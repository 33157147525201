import { IonCol, IonToast, IonAlert, IonContent, IonFooter, IonGrid, IonImg, IonLabel, IonList, IonPage, IonRow, useIonViewWillEnter, useIonToast, IonIcon, IonItem, IonModal, useIonAlert, useIonLoading, IonSlides, IonSlide } from '@ionic/react';
import './AddPage.css';
import { Capacitor } from "@capacitor/core";
import { SoundEffect } from 'capacitor-sound-effect';
import { TextToSpeech } from '@capacitor-community/text-to-speech';
import { useEffect, useRef, useState } from 'react';
import socketIOClient from "socket.io-client";
import { ENDPOINT } from '../functions';
import axios from "axios";
import { Preferences } from '@capacitor/preferences';
import { musicalNotesOutline, volumeMuteOutline } from 'ionicons/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Controller  } from 'swiper';

import 'swiper/css';
import 'swiper/css/autoplay';
import '@ionic/react/css/ionic-swiper.css';

const AddPage: React.FC = () => {
  let vidRef: any = useRef<any>(null);
  vidRef.current = [];

  const [voiceNumber, setVoiceNumber] = useState<any>(0);
  const [selectedLang, setSelectedLang] = useState<any>('es');
  const [translations, setTranslations] = useState<any>({
    en: {},
    es: {
      position: 'Posición',
      ticket: 'Turno',
      service: 'Servicio'
    }
  });
  const [date, setDate] = useState<any>(null);
  const [time, setTime] = useState<any>(null);
  const [location_id, setLocationId] = useState<any>(1);
  const [services, setServices] = useState<any>([]);
  const [employees, setEmployees] = useState<any>([]);
  const [tickets, setTickets] = useState<any>([]);
  const [got, setGot] = useState<any>(false);
  const [muted, setMuted] = useState<any>(false);
  const [vide, setVideo] = useState<any>(null);
  const [picture, setPicture] = useState<any>(null);
  const [video_filename, setVideoFileName] = useState<any>(null);
  const [video_media_type, setVideoMediaType] = useState<any>(null);
  const [media_type, setMediaType] = useState<any>('image');
  const [full_screen, setFullScreen] = useState<any>(false);
  const [invoked_automatic_screen_function, setInvokedAutomaticScreenFunction] = useState<any>(false);
  const [color_scheme, setColorScheme] = useState<any>({
    listing_header: '#68080A',
    position_box: '#B4B4B4',
    ticket_box: '#CDCDCF',
    service_box: '#D2152B',
    news_title_box: '#25AAE1',
    news_box: '#68080A'
  });
  const [header_text_size, setHeaderTextSize] = useState<any>(25);
  const [all_media, setAllMedia] = useState<any>([]);
  const [intervalz, setIntervalz] = useState<any>(10000.667);
  const [short_beep, setShortBeep] = useState<any>(0);
  const [allow_media, setAllowMedia] = useState<any>(false);
  const [ticketMediaPlaying, setTicketMediaPlaying] = useState<any>(false);
  const [connection_error, setConnectionError] = useState<any>(false);
  const [error_title, setErrorTitle] = useState<any>('');
  const [error_description, setErrorDescription] = useState<any>('');
  const [user_interection, setUserInterection] = useState<any>(false);
  const [play_slider, setPlayslider] = useState<any>(false);
  const [audio_volume, setAudioVolume] = useState<any>(1);
  const [audio_speed, setAudioSpeed] = useState<any>(1);
  const [audio_pitch, setAudioPitch] = useState<any>(1);
  const [news, setNews] = useState<any>('');
  const [showAlert, setShowAlert] = useState<any>(true);
  const [swiperSlider, setSwiperSlider] = useState<any>(null);
  const [present] = useIonToast();
  const [presentLoading, dismiss] = useIonLoading();
  // const [presentAlert] = useIonAlert();

  let elem: any;
  let ticket_arr: any = [];
  let pending_ticket_arr: any = [];
  let duration: any = 10000.667;
  let ticket_media_playing: any = false;
  let setTimeOutId: any = null;
  const socket: any = socketIOClient(ENDPOINT);
  // const socket: any = socketIOClient('https://auraqr.com:5001');

  const sleep = (ms: any) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const proceedNext = async (sentence: any) => {
    let voice_number = await voiceSettings(selectedLang);
    await SoundEffect.play({id:'message'}).then(async (res)=>{
    }).catch((err)=>{
      console.log(err);
    });
    await sleep(1500);

    await TextToSpeech.speak({
      text: sentence,
      // lang: selectedLang === 'es'? 'es-US' : 'en-US',
      voice: voice_number,
      rate: audio_speed,
      pitch: audio_pitch,
      volume: audio_volume,
      category: 'ambient',
    }).then((res)=>{
      return new Promise(resolve => {
        resolve('');
      });
    }).catch(err=>console.log(err));

    // let speech: any = new SpeechSynthesisUtterance();
    // // let voices = await window.speechSynthesis.getVoices();
    // //console.log(await window.speechSynthesis.getVoices());
    // speech.lang = "es-ES";
    // speech.voiceURI = "native";
    // speech.text = sentence;
    // speech.volume = parseFloat(audio_volume);
    // speech.rate = parseFloat(audio_speed);
    // speech.pitch = parseFloat(audio_pitch);
    // window.speechSynthesis.speak(speech);
    // return new Promise(resolve => {
    //   speech.onend = resolve;
    // });

    
  }

  const ticketCaller = async () =>{
    for await (let item of ticket_arr){
      setTicketMediaPlaying(true);
      ticket_media_playing = true;
      if(item.counter_number){
        document.getElementById("ticket-number"+item.counter_number+item.user_id)!.innerHTML = item.ticket_number;
        document.getElementById("service-name"+item.counter_number+item.user_id)!.innerHTML = item.service_name? item.service_name : '';
        var element: any = document.getElementById("ticket-number-box"+item.counter_number+item.user_id);
        element.classList.add("box-blinker");
        setTimeout(() =>{
          element.classList.remove("box-blinker");
        }, 8000);
      }else{
        document.getElementById("ticket-number")!.innerHTML = item.ticket_number;
        var element: any = document.getElementById("ticket-number-box");
        element.classList.add("box-blinker");
        setTimeout(() =>{
          element.classList.remove("box-blinker");
        }, 8000);
      }

      // let beep = new Audio(require("../assets/sounds/notification_up.mp3"));
      // await beep.play();
      // await sleep(1500);
      
      let sentence = 'Turno ';
      let splitted_ticket_number = item.ticket_number.match(/[a-zA-Z]+|[0-9]+/g);
      let ticket_number_letters = splitted_ticket_number[0];
      let ticket_number_digits = splitted_ticket_number[1];

      for await (const i of ticket_number_letters){
        sentence += i.toLowerCase() + ' ';
      }

      sentence += parseInt(ticket_number_digits) + ' ';

      if(item.counter_number){
        var counter_numberr = item.counter_number;
        counter_numberr = counter_numberr.toString();
        sentence += 'posición ' + parseInt(item.counter_number);
      }
      if(item.service_area_name){
        sentence += ' ' + item.service_area_name;
      }

      await proceedNext(sentence);
    }
    setTicketMediaPlaying(false);
    ticket_media_playing = false;

    ticket_arr = [];
    if(pending_ticket_arr.length > 0){
      for await (let item of pending_ticket_arr){
        ticket_arr.push(item);
      }
      pending_ticket_arr = [];
      setTicketMediaPlaying(true);
      ticket_media_playing = true;
      ticketCaller();
    }else{
      setTicketMediaPlaying(false);
      ticket_media_playing = false;
    }
  }

  const playServiceAreaName = async (sentence: any) => {
    // let speech: any = new SpeechSynthesisUtterance();
    // speech.lang = "es-ES";
    // speech.voiceURI = "native";
    // speech.text = sentence;
    // speech.volume = parseFloat(audio_volume);
    // speech.rate = parseFloat(audio_speed);
    // speech.pitch = parseFloat(audio_pitch);
    // window.speechSynthesis.speak(speech);

    // return new Promise(resolve => {
    //   speech.onend = resolve;
    // });
  }

  const keyboardFunction = (event: any) =>{
    if(event.keyCode === 122 || event.keyCode === 27) {
      setInvokedAutomaticScreenFunction(true);
    }
  }

  const kocha = ()=>{
    setFullScreen(!full_screen);
  }

  useEffect(()=>{
    document.addEventListener("keydown", keyboardFunction, false);
    elem = document.documentElement;
    

    ////////// connection handling ///////////////////
    socket.on('disconnect', () => {
      console.log('Disconnected from the server!');
      setErrorTitle('Disconnected from the server');
      setErrorDescription('Unfortunately connection lost to the server. Trying to reconnect to the server!');
      setConnectionError(true);
    })

    socket.on("connect", () => {
      console.log('reconnected successfully');
      setConnectionError(false);
      // alertify.success('Connection restored!')
      socket.emit("joinLocation", { location_id: location_id });
    });

    socket.on('connect_error', () => {
      console.log('connect failed');
      setErrorTitle('Failed to connect the server');
      setErrorDescription('Please check your internet connection or contact your administrator if the problem still persists!');
      setConnectionError(true);
    });
    //////////////////////////////////////////////////

    socket.on("custom_color_scheme_change", (data: any) => {
      setColorScheme({
        listing_header: data.color_scheme.listing_header_color,
        position_box: data.color_scheme.listing_position_color,
        ticket_box: data.color_scheme.listing_ticket_color,
        service_box: data.color_scheme.listing_service_color,
        news_title_box: data.color_scheme.listing_news_title_color,
        news_box: data.color_scheme.listing_news_color
      });
      setHeaderTextSize(data.listing_header_font_size);
    });

    socket.on("updated_services_acknowledgement", async (data: any) =>{
      setServices(data.services);
    });

    socket.on("employee_joins_location", async (data: any) => {
      var employee_arr = employees;
      
      for(var i = 0; i < employee_arr.length; i++){
        if(employee_arr[i].id === data.id){
          return;
        }
      }

      employee_arr.push(data);
      setEmployees(employee_arr);
      setGot(true);
    });

    socket.on("employee_leaves_location", async (data: any) => {
      var employee_arr = employees;
      for(var i = 0; i < employee_arr.length; i++){
        if(employee_arr[i].id === data.employee_id){
          employee_arr.splice(i, 1);
          break;
        }
      }
      if(employee_arr.length <= 0){
        setGot(false);
      }
      setEmployees(employee_arr);
    });

    socket.on("message_to_android", async (data: any) => {
      // console.log(data);
    });

    socket.on("called_ticket_back", async (data: any) => {
      if(data.status === 'called'){
        var service_name = false;
        var service_area_name = false;

        if(data.service_name){
          service_name = data.service_name;
        }
        if(data.service_area_name){
          service_area_name = data.service_area_name;
        }

        let service_letter: any = '';
        if(data.service_letter){
          service_letter = data.service_letter;
        }
        var ticket_number = data.ticket_number;
        ticket_number = ticket_number.toString();
        while (ticket_number.length < 3) {
          ticket_number = "0" + ticket_number;
        }
        ticket_number = service_letter+ticket_number;

        if(!muted){
          let number = '';
          for(const i of ticket_number){
            number += i.toLowerCase();
          }

          if(data.counter_number){
            // if(!ticketMediaPlaying){
            if(!ticket_media_playing){
              ticket_arr.push({
                ticket_number: ticket_number,
                counter_number: data.counter_number,
                service_name: service_name,
                service_area_name: service_area_name,
                user_id: data.user_id
              });
              ticketCaller();
            }else{
              pending_ticket_arr.push({
                ticket_number: ticket_number,
                counter_number: data.counter_number,
                service_name: service_name,
                service_area_name: service_area_name,
                user_id: data.user_id
              });
            }
          }else{
            var sentence = 'numero de ticket '+ticket_number+' por favor visite el mostrador';
            // if(!ticketMediaPlaying){
            if(!ticket_media_playing){
              ticket_arr.push({
                ticket_number: ticket_number,
                counter_number: false,
                service_name: service_name,
                service_area_name: service_area_name,
                user_id: data.user_id
              });
              ticketCaller();
            }else{
              pending_ticket_arr.push({
                ticket_number: ticket_number,
                counter_number: false,
                service_name: service_name,
                service_area_name: service_area_name,
                user_id: data.user_id
              });
            }
          }
          setTicketMediaPlaying(true);
          ticket_media_playing = true;
        }else{
          if(data.counter_number){
            document.getElementById("ticket-number"+data.counter_number+data.user_id)!.innerHTML = ticket_number;
            document.getElementById("service-name"+data.counter_number+data.user_id)!.innerHTML = data.service_name;
            var element: any = document.getElementById("ticket-number-box"+data.counter_number+data.user_id);
            element.classList.add("box-blinker");
            setTimeout(() =>{
              element.classList.remove("box-blinker");
            }, 4000);
          }else{
            document.getElementById("ticket-number")!.innerHTML = ticket_number;
            var element: any = document.getElementById("ticket-number-box");
            element.classList.add("box-blinker");
            setTimeout(() =>{
              element.classList.remove("box-blinker");
            }, 4000);
          }
        }
      }
    });
  }, []);

  const handleMute = () =>{
    setMuted(!muted);
  }

  //the following commented function is not needed just keep for reference/////////////////////////
  // const handleLanguageChange = (e: any) =>{
  //   i18n.changeLanguage(e.target.id);
  // }

  // const handleFullscreen = () =>{
  //   if (elem.requestFullscreen) {
  //     setFullScreen(true);
  //     elem.requestFullscreen();
  //   } else if (elem.webkitRequestFullscreen) { /* Safari */
  //     elem.webkitRequestFullscreen();
  //   } else if (elem.msRequestFullscreen) { /* IE11 */
  //     elem.msRequestFullscreen();
  //   }
  // }

  // const handleExitFullscreen = () =>{
  //   if (document.exitFullscreen) {
  //     setFullScreen(false);
  //     document.exitFullscreen();
  //   } else if (document.webkitExitFullscreen) { /* Safari */
  //     document.webkitExitFullscreen();
  //   } else if (this.elem.msExitFullscreen) { /* IE11 */
  //     document.msExitFullscreen();
  //   }
  // }
  ////////////////////////////////////////////////////////////////

  const handleStartSlides = async () => {
    setUserInterection(true);
    setPlayslider(true);
  }

  const onChange = (index: any, item: any) => {
    setIntervalz(item.props["data-interval"]);
    
    if(item.props.children.type === 'video'){
        vidRef.current[index].pause();
        vidRef.current[index].currentTime = 0;
        vidRef.current[index].muted = false;
        vidRef.current[index].volume = all_media[index].volume / 100;
        vidRef.current[index].play();
    }
  };

  const locationInfoRequest = (location_id: any) => {
    presentLoading({
      message: 'Loading...',
    });
    // axios.defaults.withCredentials = true;
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    const url = ENDPOINT + "/get_location_services_and_serving_tickets";
    axios.post(
      url,
      JSON.stringify({ location_id: location_id }),
      config
    )
    .then(async (response: any) => {
      dismiss();
      if(!response.data.status){
        present({
          message: response.data.message,
          duration: 1500,
          position: 'bottom',
        });
        setTimeout(()=>{
          setShowAlert(true);
        }, 500);
      }else{
        setShowAlert(false);
        setLocationId(location_id);
        await Preferences.set({
          key: 'aura-location-id',
          value: JSON.stringify(location_id),
        });

        handleJoinLocation();

        var news = '';
        for await (let item of response.data.news){
          news += item.feed + ' >>> ';
        }

        // let media = [{
        //   duration: 0.1,
        //   id: 0,
        //   location_id: 0,
        //   media: "black.jpg",
        //   media_type: "image/jpg",
        //   muted: 0,
        //   volume: "50"
        // }];
        let media = [];
        for await (const item of response.data.location_media){
          media.push(item);
        }

        setColorScheme({
          listing_header: response.data.location_data[0].listing_header_color,
          position_box: response.data.location_data[0].listing_position_color,
          ticket_box: response.data.location_data[0].listing_ticket_color,
          service_box: response.data.location_data[0].listing_service_color,
          news_title_box: response.data.location_data[0].listing_news_title_color,
          news_box: response.data.location_data[0].listing_news_color,
        });
        setHeaderTextSize(response.data.location_data[0].listing_header_font_size);
        setAllMedia(media);
        setShortBeep(response.data.location_data[0].short_beep);
        setAllowMedia(response.data.location_data[0].allow_media);
        setIntervalz(response.data.location_media.length > 0? media[0].duration * 1000 : 1000);
        setGot(response.data.employees.length > 0 || response.data.tickets.length > 0? true : false);
        setServices(response.data.services);
        setEmployees(response.data.employees);
        setTickets(response.data.tickets);
        setNews(news);
        setAudioVolume(response.data.audio_volume);
        setAudioSpeed(response.data.audio_speed);
        setAudioPitch(response.data.audio_pitch);

        duration = response.data.location_media.length > 0? response.data.location_media[0].duration * 1000 : 1000;
      }
    })
    .catch((error) => {
      console.log(error);
      present({
        message: 'There is an error',
        duration: 5000,
        position: 'bottom',
      });
    });
  }

  const handleJoinLocation = async (event: any = '') => {
    const { value } = await Preferences.get({ key: 'aura-location-id' });
    if(value){
      if(event === 'onload'){
        locationInfoRequest(JSON.parse(value));
      }
      socket.emit("joinLocation", {location_id: JSON.parse(value)});
      // socket.emit("get-location-information-and-services-and-employees-information", {location_id: location_id});
    }
  }


  useIonViewWillEnter(async ()=>{
    handleJoinLocation('onload');

    SoundEffect.loadSound({id:'message', path:'assets/sounds/notification_up.mp3'});

    if(Capacitor.isNative){
      await TextToSpeech.openInstall();
      voiceSettings('es');
    }
    
    // voiceSettings('es');
  });

  const voiceSettings = async (lang: any) => {
    const voices: any = await TextToSpeech.getSupportedVoices();
    const langs: any = await TextToSpeech.getSupportedLanguages();
    // console.log(voices);
    console.log(langs);
    let voice_number = 0;
    for await (let voice of voices.voices){
      if(lang === 'es' && voice.lang === 'es-US'){
        setVoiceNumber(voice_number);
        break;
      }else if(lang === 'en' && voice.lang === 'en-GB'){
        setVoiceNumber(voice_number);
        break;
      }
      voice_number++;
    }
    console.log(voice_number);
    return voice_number;
  }

  const handleSwiperSlideMovement = (data: any, type: any = '') => {
    if(all_media[data.realIndex].media_type.includes('video')){
      vidRef.current[data.realIndex].currentTime = 0;
      vidRef.current[data.realIndex].muted = false;
      vidRef.current[data.realIndex].volume = all_media[data.realIndex].volume / 100;
      vidRef.current[data.realIndex].play();
    }
    if(setTimeOutId){
      clearTimeout(setTimeOutId);
    }
    setTimeOutId = setTimeout(() => {
      if(all_media[data.realIndex].media_type.includes('video')){
        console.log(vidRef.current[data.realIndex]);
        vidRef.current[data.realIndex].muted = true;
        vidRef.current[data.realIndex].currentTime = 0;
      }
      data.slideNext();
    }, all_media[data.realIndex].duration * 1000);
  }

  const handleClick = () => {

    // SoundEffect.play({id:'message'}).then(async (res)=>{
    //   setTimeout(async ()=>{
    //     await TextToSpeech.speak({
    //       text: selectedLang === 'es'? 'Turno R890 posición 1' : 'Ticket H125 position 5',
    //       lang: selectedLang === 'es'? 'es-ES' : 'en-GB',
    //       voice: voiceNumber,
    //       rate: 1,
    //       pitch: 1,
    //       volume: 1.0,
    //       category: 'ambient',
    //     });
    //   }, 1000);
    // }).catch((err)=>{
    //   console.log(err);
    // });
  }

  const handleGetLocationInfo = (location_id: any) => {
    if(location_id === '' || location_id === ' '){
      present({
        message: 'Please enter a valid location id',
        duration: 2000,
        position: 'bottom',
      });
      setTimeout(()=>{
        setShowAlert(true);
      }, 500);
    }else{
      locationInfoRequest(location_id);
    }
  }

  const handleCheckLocationId = async () => {
    const { value } = await Preferences.get({ key: 'aura-location-id' });
    if(value){
      setShowAlert(false);
    }
  }

  const handleLocationIdChange = () => {
    setShowAlert(true);
  }

  if(employees.length > 0){
    var listAll = employees.map((emp: any, key: any)=>{
      var ticket_number = '---';
      var service_name = '';
      for(var i = 0; i < tickets.length; i++){
        if(emp.serving_position === tickets[i].called_at_position){
          ticket_number = tickets[i].ticket_number;
          ticket_number = ticket_number.toString();

          while (ticket_number.length < 3) {
            ticket_number = "0" + ticket_number;
          }
          if(tickets[i].service_letter){
            ticket_number = tickets[i].service_letter+ticket_number;
          }

          service_name = tickets[i].service_name;
          break;
        }
      }
      return(
        <IonList key={key} >
          <IonGrid className='ion-no-padding'>
            <IonRow>
              <IonCol size='2' className='ion-text-center text-show bg-gray' style={{backgroundColor: color_scheme.position_box}}>
                <IonLabel>{emp.serving_position}</IonLabel>
              </IonCol>
              <IonCol size='2' className='ion-text-center text-show bg-light-gray' id={"ticket-number-box"+emp.serving_position+emp.id} style={{backgroundColor: color_scheme.ticket_box}}>
                <IonLabel id={"ticket-number"+emp.serving_position+emp.id}>{ticket_number}</IonLabel>
              </IonCol>
              <IonCol size='8' className='text-white bg-red text-show' style={{backgroundColor: color_scheme.service_box}}>
                <IonLabel id={"service-name"+emp.serving_position+emp.id}>{service_name? service_name : ''}</IonLabel>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonList>
      );
    });
  } else{
    if(tickets.length > 0){
      var listAll = tickets.map(function(ticket: any, key: any){
        return(
          <IonList key={key}>
            <IonGrid className='ion-no-padding'>
              <IonRow>
                <IonCol size='2' className='ion-text-center text-show bg-gray' style={{backgroundColor:color_scheme.listing_position_color}}>
                  <IonLabel>---</IonLabel>
                </IonCol>
                <IonCol size='2' className='ion-text-center text-show bg-light-gray' style={{backgroundColor: color_scheme.ticket_box}}>
                  <IonLabel>{ticket.ticket_number}</IonLabel>
                </IonCol>
                <IonCol size='8' className='text-white bg-red text-show' style={{backgroundColor: color_scheme.service_box}}>
                  <IonLabel>---</IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonList>
        );
      }, this);
    }
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonAlert
          isOpen={showAlert}
          header="Location ID"
          subHeader="Please enter location id"
          buttons= {[{
            text: 'OK',
            handler: (data) =>{
              handleGetLocationInfo(data[0]);
            }
          }]}
          inputs= {[{
            type: 'text',
            placeholder: 'Location id',
            min: 1
          },]}
          keyboardClose= {false}
          onDidDismiss={()=>{
            setShowAlert(false);
          }}
        ></IonAlert>
        <IonGrid>
          <IonRow>
            <IonCol size='11'>
              <p className='change-loc-id' onClick={()=>{handleLocationIdChange()}}>Change Location ID</p>
            </IonCol>
            <IonCol size='1'>
              <div className='flag-box'>
                {muted?
                  <IonIcon icon={musicalNotesOutline} size="large" onClick={handleMute} style={{width: '200px'}}></IonIcon>
                  :
                  <IonIcon icon={volumeMuteOutline} size="large" onClick={handleMute} style={{width: '200px'}}></IonIcon>
                }
                <IonImg src={require("../assets/images/esp-flag.png")}  onClick={()=>{
                  setSelectedLang('es');
                  voiceSettings('es');
                }}/>
                <IonImg src={require("../assets/images/eng-flag.png")} onClick={()=>{
                  setSelectedLang('en');
                  voiceSettings('en');
                }}/>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonModal id="example-modal" isOpen={connection_error}>
              <div className="wrapper">
                <h1>{error_title}</h1>
                <p>{error_description}</p>
              </div>
            </IonModal>
            {allow_media? 
              <IonCol size='5'>
                {/* <video style={{width: '100%'}} controls={true} preload={'metadata'} autoPlay={true}>
                  <source src={`https://auraqr.com:5000/video/1686932627004.mp4`}/>
                  {'Your browser does not support the video tag..1'}
                </video> */}
                {all_media.length > 0?
                  // <IonImg src={require("../assets/images/placeholder.png")} />
                  <Swiper 
                    autoplay={false}
                    loop={true}
                    onSlideChangeTransitionEnd={(swiper)=>{
                      handleSwiperSlideMovement(swiper, 'slide change');
                    }}
                    onInit={(swiper)=>{
                      handleSwiperSlideMovement(swiper, 'after init');
                    }}
                  >
                    {all_media.map((item: any, index: any)=>{
                      if(item.media_type.includes("image")){
                        return(
                          <SwiperSlide key={index}>
                            <img style={{width: '100%'}} src={`${ENDPOINT}/image/${item.media}`}/>
                          </SwiperSlide>
                        );
                      }else if(item.media_type.includes("video")){
                        return(
                          <SwiperSlide key={index}>
                            <video style={{width: '100%'}} id={`vid-${index}`} preload={'auto'} ref={(el) => {vidRef.current[index] = el}}>
                              <source src={`${ENDPOINT}/video/${item.media}`} type={item.media_type}/>
                              {'Your browser does not support the video tag..1'}
                            </video>
                          </SwiperSlide>
                        );
                      }
                    })}
                  </Swiper>
                : 
                  <IonImg style={{width: '100%', height: '100%'}} id="media-photo" src={require('../assets/images/placeholder.png')}/>
                }
              </IonCol>
            : 
              ''
            }
            <IonCol size={allow_media? '7' : '12'}>
              <IonList className='header-wrapper bg-brown' style={{backgroundColor: color_scheme.listing_header}}>
                <IonGrid>
                  <IonRow class='ion-text-center'>
                    <IonCol size='2' style={{backgroundColor: color_scheme.listing_header}}>
                      <IonLabel onClick={()=> handleClick()} style={{marginTop: '15px', fontWeight: 'bold', wordBreak: 'break-all', fontSize: header_text_size+'px'}}>{selectedLang === 'es'? translations.es.position : 'Position'}</IonLabel>
                    </IonCol>
                    <IonCol size='2' style={{backgroundColor: color_scheme.listing_header}}>
                      <IonLabel style={{marginTop: '15px', fontWeight: 'bold', wordBreak: 'break-all', fontSize: header_text_size+'px'}}>{selectedLang === 'es'? translations.es.ticket : 'Ticket'}</IonLabel>
                    </IonCol>
                    <IonCol size='8' style={{backgroundColor: color_scheme.listing_header}}>
                      <IonLabel style={{marginTop: '15px', fontWeight: 'bold', wordBreak: 'break-all', fontSize: header_text_size+'px'}}>{selectedLang === 'es'? translations.es.service : 'Service'}</IonLabel>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonList>
              {got?
                  listAll
                  :
                  <IonList>
                    <IonGrid className='ion-no-padding'>
                      <IonRow>
                        <IonCol size='2' className='ion-text-center text-show bg-gray' style={{backgroundColor: color_scheme.listing_position_color}}>
                          <IonLabel>---</IonLabel>
                        </IonCol>
                        <IonCol size='2' className='ion-text-center text-show bg-light-gray' style={{backgroundColor: color_scheme.ticket_box}}>
                          <IonLabel>---</IonLabel>
                        </IonCol>
                        <IonCol size='8' className='text-white bg-red text-show' style={{backgroundColor: color_scheme.service_box}}>
                          <IonLabel>---</IonLabel>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                </IonList>
              }
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonFooter className='ion-no-border p-4' mode='md'>
          <IonGrid>
            <IonRow>
              <IonCol size='3' className='bg-ligt-blue text-white ion-padding-start heading-medium' style={{backgroundColor: color_scheme.news_title_box}}>
                <IonLabel>News</IonLabel>
              </IonCol>
              <IonCol size='9' className='bg-brown overflow-hidden' style={{backgroundColor: color_scheme.news_box}}>
                <p className='text-secondary ion-text-center ion-text-nowrap p-medium news-ticker'>{news}</p>
              </IonCol>
              <div className='ion-margin-top ion-margin-bottom ion-d-flex  ion-justify-content-center ion-align-items-center w-full'>
                <IonImg className='w-100' src={require("../assets/images/logo.png")} />
                <div><span><a href="https://www.auraturno.com">www.auraturno.com</a> | 809-602-5000</span></div>
              </div>
            </IonRow>
          </IonGrid>
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default AddPage;
